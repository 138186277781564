<template>
  <div class="example">
    <!-- <div class="toolbar">
      <button
        @click="toSlide(0)"
      >
        To Slide 1
      </button>
      <button
        @click="toSlide(249)"
      >To Slide 250
      </button>
      <button
        @click="toSlide(499)"
      >
        To Slide 500
      </button>
    </div> -->
    <h1>Carica Prodotti</h1>
    <b-card>
      <swiper
        ref="mySwiper"
        class="swiper"
        :options="swiperOption"
      >
        <swiper-slide
          v-for="data in swiperData"
          :key="data.name+data.id"
          class="rounded swiper-shadow"
        >
          <div
            class="d-flex flex-column justify-content-center"
            style="height: 100%; max-width: 100px!important;"
            @click="toSlide(data.name, data.address, data.tel, data.referente, data.id, data.products)"
          >
            <b-img
              :src="require('@/@core/assets/logo/marchio-intero-fishup.svg')"
              alt="Card image cap"
              class="mx-auto"
              width="60px"
            />
            <!-- <feather-icon
              class="mx-auto"
              :icon="data.icon"
              size="28"
            /> -->
            <div class="swiper-text pt-md-1 pt-sm-50 text-black">
              {{ data.name }}
            </div>
          </div>
        </swiper-slide>
        <!-- <div
          slot="pagination"
          class="swiper-pagination"
        /> -->
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
        <div
          slot="button-next"
          class="swiper-button-next"
        />
      </swiper>
    </b-card>
    <div v-if="showShop">
      <b-card
        :title="'Punto Vendita Selezionato: '+ selectedShop.name+ '(id:'+ selectedShop.id +')'"
      >
        <b-card-text>
          <b-row>
            <b-col>
              <b-avatar
                size="60px"
                :src="require('@/@core/assets/logo/marchio-intero-fishup.svg')"
                class="mx-auto"
              />
            </b-col>
            <b-col
              cols="9"
              lg="11"
              class="d-flex"
            >
              <b-form-group
                label="Quantià Magazzino Generale"
                label-for="inputQuantitySelectedProductDisalbe"
              >
                <b-input-group
                  prepend="KG"
                >
                  <b-form-input
                    id="inputQuantitySelectedProductDisalbe"
                    :value="selectedShop.products.map(item => item.quantity).reduce((prev, next) => prev + next)"
                    disabled
                    style="max-width:150px"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col
              cols="9"
              lg="11"
              class="d-flex"
            >
              <validation-observer ref="changePriceProduct">
                <validation-provider
                  #default="{ errors }"
                  name="Cambio Prezzo"
                  rules="required"
                >
                  <b-form-group
                    label="Modifica Prezzo"
                    label-for="inputPriceSelectedProductDisalbe"
                    class=""
                  >
                    <b-input-group
                      prepend="€"
                    >
                      <b-form-input
                        id="inputPriceSelectedProductDisalbe"
                        v-model="editPrice"
                        style="max-width:100px"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                  <b-form-checkbox
                    v-model="changeForAll"
                    value="Y"
                    class="mb-2"
                  >
                    Cambia per tutti i Punti Vendita
                  </b-form-checkbox>
                </validation-provider>
              </validation-observer>
            </b-col> -->
          </b-row>
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="saveProductPrice"
          >
            Cambia1
          </b-button> -->
        </b-card-text>
      </b-card>
    </div>
    <div v-if="showShop">
      <b-row>
        <b-col
          v-for="product in selectedShop.products"
          :key="product.text"
          cols="12"
          md="6"
          lg="4"
          xl="3"
        >
          <b-card no-body>
            <b-card-body>
              <b-card-title
                class="d-flex justify-content-between"
              >
                <div>
                  {{ product.text }}
                </div>
                <div>
                  {{ selectedShop.name }}
                </div>
              </b-card-title>
              <!-- <b-card-sub-title>{{ shop.address }}</b-card-sub-title> -->
            </b-card-body>
            <b-img
              :src="product.icon"
              alt="Card image cap"
              class="mx-auto"
              width="100px"
            />
            <b-card-body>
              <!-- <b-card-text><span>Tel: {{ shop.tel }}</span> <span class="d-block">Referente {{ shop.referente }}</span></b-card-text> -->
              <hr>
              <b-card-text>
                <span><strong>Prezzo attuale: {{ product.price }} €</strong></span>
                <span class="d-block"><strong>Quantità attuale: {{ product.quantity / 4 * Math.floor(Math.random() * (10 * 100 - 1 * 100) + 1 * 100) / (1*100) }} KG</strong></span>
              </b-card-text>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Inserisci Qnt."
                    label-for="inputQuantitySelectedProductDisalbe2"
                  >
                    <b-input-group
                      prepend="KG"
                    >
                      <b-form-input
                        id="inputQuantitySelectedProductDisalbe2"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Nuovo Prezzo"
                    label-for="inputPriceSelectedProductDisalbe2"
                    class=""
                  >
                    <b-input-group
                      prepend="€"
                    >
                      <b-form-input
                        id="inputPriceSelectedProductDisalbe2"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row no-gutters>
                <b-col
                  class="d-flex justify-content-end"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="cambia(product, 'y')"
                  >
                    Cambia
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BRow, BCol, BCard, BFormInput, BCardText, BAvatar, BFormGroup, BInputGroup, BButton, BCardBody, BCardTitle, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCard,
    BCardText,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BButton,
    // BLink,
    BCardBody,
    BCardTitle,
    // BCardSubTitle,
    BImg,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      showShop: false,
      changeForAll: 'N',
      editPrice: '',
      selectedShop: {
        name: '',
        address: '',
        tel: '',
        referente: '',
        id: '',
        products: [],
      },
      products: [
        /* eslint-disable */
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
        { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
      ],
      swiperOption: {
        slidesPerView: 10,
        centeredSlides: false,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 8,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
      },
      swiperData: [
        {
          name: "Canalicchio",
          address: "via etnea 120",
          tel: "095-000001",
          referente: "Mario Rossi",
          id: 1,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 40, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 50, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Via Pacinotti",
          address: "via etnea 150",
          tel: "095-000002",
          referente: "Mario Rossi2",
          id: 2,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 30, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 10, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 50, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 60, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 15, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 20, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 200, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Belpasso",
          address: "corso san vito",
          tel: "095-000003",
          referente: "Mario Rossi3",
          id: 3,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 35, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Piano Tavola",
          address: "corso san vito",
          tel: "095-000003",
          referente: "Mario Rossi3",
          id: 4,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 30, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 60, price: 8 },
          ]
        },
        {
          name: "Siena",
          address: "via etnea 1",
          tel: "095-000005",
          referente: "Mario Rossi2",
          id: 5,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 10, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Giarre",
          address: "viale XX settembre",
          tel: "095-000005",
          referente: "Mario Rossi2",
          id: 6,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 65, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 55, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "San Giovanni la Punta",
          address: "via etnea 65",
          tel: "095-000006",
          referente: "Mario Rossi2",
          id: 7,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 40, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Paternò",
          address: "corso san vito 56",
          tel: "095-000007",
          referente: "Mario Rossi3",
          id: 8,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Acireale",
          address: "corso san vito 57",
          tel: "095-000008",
          referente: "Mario Rossi3",
          id: 9,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 160, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Caltagirone",
          address: "piazza dante 55 ",
          tel: "095-000008",
          referente: "Mario Rossi3",
          id: 10,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 80, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Randazzo",
          address: "piazza dante 55 ",
          tel: "095-000008",
          referente: "Mario Rossi3",
          id: 11,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 30, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Poggio Lupo",
          address: "piazza dante 55 ",
          tel: "095-000008",
          referente: "Mario Rossi3",
          id: 12,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 15, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Palagonia",
          address: "piazza dante 55 ",
          tel: "095-000008",
          referente: "Mario Rossi3",
          id: 13,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 80, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 100, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Vampolieri",
          address: "piazza dante 55 ",
          tel: "095-000008",
          referente: "Mario Rossi3",
          id: 14,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 150, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Rapisarda",
          address: "piazza dante 55 ",
          tel: "095-000008",
          referente: "Mario Rossi3",
          id: 15,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 200, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 50, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
        {
          name: "Ughetti",
          address: "piazza dante 55 ",
          tel: "095-000008",
          referente: "Mario Rossi3",
          id: 16,
          products: [
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Orata', quantity: 100, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Polpo', quantity: 40, price: 11 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Alici', quantity: 60, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo', quantity: 80, price: 15 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Pesce Spada', quantity: 40, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie', quantity: 70, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Calamari', quantity: 120, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Aragosta', quantity: 140, price: 25 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamberetti', quantity: 45, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Gamb Giganti', quantity: 30, price: 18 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola', quantity: 70, price: 13 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Cozze', quantity: 140, price: 5 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Seppie Nere', quantity: 50, price: 9 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Frutti di mare', quantity: 80, price: 10 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Merluzzo(cong)', quantity: 90, price: 7 },
            { icon: require('@/@core/assets/logo/marchio-intero-fishup.svg'), text: 'Spigola(cong)', quantity: 100, price: 8 },
          ]
        },
      ],
    }
  },
  computed: {
    locale() {
      return 'it'
    },
  },
  methods: { 
    cambia(product, y) {
      if(y === 'y') {
        this.$swal({
          title: 'Inserimento Completato Correttamente',
          text: `Prezzo cambiato correttamente`,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    saveProductPrice() {
      this.$refs.changePriceProduct.validate().then(async success => {
        if (success) {
          this.$swal({
          title: 'Cambio prezzo effettuato',
          // text: 'Controlla la posta per confermare',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          })
        }
      })
    },
    toSlide(name, address, tel, referente, id, products) {
      this.selectedShop.name = name
      this.selectedShop.address = address
      this.selectedShop.tel = tel
      this.selectedShop.referente = referente
      this.selectedShop.id = id
      this.selectedShop.products = [...products]
      this.showShop = true
      // console.log('this', this.selectedShop)
      // this.$refs.mySwiper.$swiper.slideTo(index, 0)
    },
  },
}
</script>

<style lang="scss" scoped>

  .example {
    height: auto;

    .swiper {
      height: 200px;
      width: 100%;

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        border: 1px solid blue;
      }
    }

    .toolbar {
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 2rem;

      button {
        flex: 1;
        padding: 0;
        margin: 0;
        border: none;
        border-right: 1px solid black;
        background-color: white;
        cursor: pointer;
        &:last-child {
          border: none;
        }

        &:hover {
          background-color: white;
        }
      }
    }
  }
</style>